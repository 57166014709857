<template>
  <div id="app">
    <div class="app-header">
      <index-header v-if="href != 'hnkdtdxlkspx.ykumda.com'" />
      <header-hnkj v-else />
    </div>
    <div class="app-main" :class="footerLayoutEunm[footerType]">
      <div class="main_bg">
        <div class="main_user_info">
          <div class="user_info">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img :src="data.userInfo.headImg" alt="加载中..." title="点击更换头像" />
              <div class="head_img_tip">更换头像</div>
            </el-upload>
            <div class="user_msg">
              <div class="title">
                <span>{{ data.userInfo.nickname }}</span>
                <NuxtLink v-if="data.userInfo.isVip" to="/vip">
                  <img src="@/assets/pc/icon_vip.png" alt="" />
                </NuxtLink>
              </div>
              <span>{{ userPhone }}</span>
              <span class="user_info_expirationDate" v-if="data.userInfo.expirationDate"
                >( 到期时间：{{ data.userInfo.expirationDate.slice(0, 10) }} )</span
              >
              <div class="user_info_class" v-if="purchaseSwitch">
                学号：{{ data.userInfo.studentNo }}
              </div>
              <div class="user_info_class" v-if="purchaseSwitch">
                报读专业：{{ data.userInfo.studentMajor }}
              </div>
            </div>
          </div>
          <div class="user_course_msg">
            <div class="user_course_msg_box">
              <div class="user_course_msg_num">
                {{ opts.componentInfo.alreadyLearnCourseNum }}
              </div>
              <div class="user_course_msg_text">已学课程数</div>
            </div>
            <div class="user_course_msg_box">
              <div class="user_course_msg_num">
                {{ opts.componentInfo.notesNum }}
              </div>
              <div class="user_course_msg_text">笔记数</div>
            </div>
            <div class="user_course_msg_box">
              <div class="user_course_msg_num">
                {{ opts.componentInfo.collectionNum }}
              </div>
              <div class="user_course_msg_text">收藏数</div>
            </div>
            <div class="user_course_msg_box">
              <div class="user_course_msg_num">
                {{ opts.componentInfo.alreadyGainCertNum }}
              </div>
              <div class="user_course_msg_text">已获证书</div>
            </div>
          </div>
        </div>
      </div>
      <div class="main w">
        <div class="side_box">
          <div class="side">
            <div
              v-for="(item, index) in data.sideList"
              :key="index"
              class="item"
              :class="{
                active: item.path === data.currentItem,
              }"
              @click="toLink(item.path)"
            >
              <span :class="`iconfont ${item.icon}`" />
              <span >{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="main_container">
          <slot />
        </div>
      </div>
    </div>
    <div class="app-footer">
      <index-footer />
    </div>
    <back-top />
    <suspense-box />
    <message-box />
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useUserStore } from "@/stores/user";
import { uploadAuthPic } from "@/api/upload";
import { ElMessage } from "element-plus";
import cookie from "@/utils/cookies";
import { useLayoutStore } from "@/stores/layout";
import { userUpdateHeadImg, getUserGain } from "@/api/user";
import headerHnkj from "@/components/index/header/headerHnkj.vue";

const layoutStore = useLayoutStore();
const footerType = computed(() => layoutStore.footerType);
const footerLayoutEunm = ref({
  1: "footer-style-one",
  2: "footer-style-two",
  3: "footer-style-three",
});
const store = useUserStore();
const { getUserView, getShopInfo, shopData } = store;
const purchaseSwitch = computed(() => {
  const enabledBranchShop = shopData?.enabledBranchShop;
  return enabledBranchShop == undefined || enabledBranchShop == null ? true : enabledBranchShop;
});

const href = ref(null)
const hrefIshow = ref(false)
getShopInfo();
const opts = reactive({
  componentInfo: {},
});
const route = useRoute();
const router = useRouter();
const data = reactive({
  currentItem: "",
  userInfo: computed(() => store.userView),
  sideList: [
    {
      name: "我的课程",
      path: "/account/course",
      icon: "icon-m_course",
    },
    {
      name: "我的班级",
      path: "/account/grade",
      icon: "icon-m_class",
      show: true,
    },
    {
      name: "我的题库",
      path: "/account/paper",
      icon: "icon-m_paper",
    },
    {
      name: "我的作业",
      path: "/account/homework",
      icon: "icon-m_paper",
    },
    {
      name: "我的考试",
      path: "/account/exam",
      icon: "icon-m_paper",
    },
    {
      name: "我的成绩",
      path: "/account/score",
      icon: "icon-m_paper",
    },
    {
      name: "统考成绩",
      path: "/account/generalexam",
      icon: "icon-m_paper",
    },
    {
      name: "我的论文",
      path: "/account/thesis",
      icon: "icon-m_paper",
    },
    {
      name: "我的资料",
      path: "/account/material",
      icon: "icon-m_paper",
    },
    {
      name: "我的笔记",
      path: "/account/note",
      icon: "icon-m_note",
    },
    {
      name: "我的证书",
      path: "/account/certificate",
      icon: "icon-m_cert",
    },
    // {
    //   name: "我的订单",
    //   path: "/account/order",
    //   icon: "icon-m_order",
    //   show: purchaseSwitch.value,
    // },
    {
      name: "我的收藏",
      path: "/account/collect",
      icon: "icon-m_collect",
    },
    // {
    //   name: "优惠券",
    //   path: "/account/coupon",
    //   icon:'icon-m_coupon'
    // },
    {
      name: "通知消息",
      path: "/account/message",
      icon: "icon-m_notice",
    },
    {
      name: "资料与账号",
      path: "/account/info",
      icon: "icon-m_user",
    },
    {
      name: "我的流程",
      path: "/account/flow",
      icon: "icon-m_paper",
    },
    // {
    //   name: "直播课表",
    //   path: "/account/schedule",
    //   icon: "icon-m_paper",
    // }
  ],
});
data.sideList = data.sideList.filter((item) => item.show != false);
const userPhone = computed({
  get: () => {
    const phone = data.userInfo.phone;
    if (phone) return phone.substr(0, 3) + " **** " + phone.substr(7);
    else return "";
  },
});

watch(route, (newValue, oldValue) => {
  data.currentItem = route.path;
});


onMounted(async () => {
  // console.log(shopData.enabledBranchShop)
  href.value = window.location.host
  if(href.value == 'www.ykumda.com') {
    data.sideList[1].show = false
    data.sideList = data.sideList.filter((item) => item.show != false);
  }
  opts.componentInfo = await getUserGain();
  data.currentItem = route.path;
  getUserView(true);
});
const cookieData = cookie.getShop();
useHead({
  link: [{ rel: "icon", type: "image/x-icon", href: cookieData.shopIcon }],
});

const toLink = (path) => {
  router.push({ path });
};

const handleAvatarSuccess = (res, file) => {
  const formData = new FormData();
  formData.append("picFile", file.raw);
  uploadAuthPic(formData).then((res) => {
    if (res && res.data) {
      data.userInfo.headImg = res.data.fileUrl;
      userUpdateHeadImg({
        headImg: res.data.fileUrl,
      }).then(() => {
        getUserView(true);
      });
    }
  });
};
const beforeAvatarUpload = (file) => {
  const isJPG = file.type === "image/jpeg" || file.type === "image/png";
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isJPG) {
    ElMessage.error("Avatar picture must be JPG format!");
  }
  if (!isLt2M) {
    ElMessage.error("Avatar picture size can not exceed 2MB!");
  }
  return isJPG && isLt2M;
};
</script>

<style lang="scss" scoped>
#app {
  position: relative;
  background-color: #f6f8fb;
}

.head_img_tip {
  position: absolute;
  top: 0px;
  opacity: 0;
  width: 120px;
  height: 120px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120px;
  z-index: 2;
  text-align: center;
  transition: all 0.2s;
}
.avatar-uploader {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
}
.avatar-uploader:hover {
  .head_img_tip {
    top: 0;
    opacity: 1;
  }
}
.app-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
}
.user_course_msg {
  display: flex;
  align-items: center;
  .user_course_msg_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    &:after {
      content: "";
      width: 54px;
      height: 0px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      transform: rotate(105deg);
      position: absolute;
      right: -60px;
    }
  }
  .user_course_msg_box:last-child:after {
    width: 0;
  }
  .user_course_msg_num {
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
  }
  .user_course_msg_text {
    color: rgba(255, 255, 255, 0.7);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 4px;
  }
  .user_course_msg_box + .user_course_msg_box {
    padding-left: 54px;
  }
}
.user_info {
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  color: #999;
  display: flex;
  align-items: center;
  .user_msg {
    margin-left: 20px;
    max-width: 270px;
  }
  .user_msg span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  .avatar-uploader img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      text-align: left;
      color: #ffffff;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 10px;
    }
  }
}
.app-main {
  min-height: 100vh;
  padding-top: 66px;
  background-color: #f6f8fb;
  .main_bg {
    height: 150px;
    background: url("@/assets/images/account_bg.png") no-repeat;
    background-size: cover;
  }
  .main_user_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: auto;
    height: 100%;
  }
  .main {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    .side_box {
      width: 200px;
      margin-right: 10px;
      .side {
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        padding: 20px;
        .item + .item {
          margin-top: 18px;
        }
        .item {
          font-size: 16px;
          padding-left: 20px;
          display: flex;
          align-items: center;
          height: 50px;
          cursor: pointer;
          position: relative;
          .iconfont {
            font-size: 16px;
            margin-right: 8px;
          }
          &.active,
          &:hover {
            background: rgba(61, 127, 255, 0.1);
            border-radius: 4px;
            color: #3d7fff;
          }
        }
      }
    }
    .main_container {
      background: #fff;
      border-radius: 10px;
      width: 990px;
      padding-bottom: 20px;
    }
  }

  .user_info_expirationDate {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 5px;
    // color: #f56c6c;
  }
  .user_info_class {
    padding-top: 2px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
  }
}
</style>
